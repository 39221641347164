<template>
  <div class="flex justify-center pt-6 mt-20 md:mt-4 pb-10">
    <table id="Vendedores" class="table-auto">
      <thead>
        <tr>
          <th class="px-4 py-2"></th>
          <th class="px-4 py-2"></th>
          <th colspan="5" class="border-2 px-4 py-2">Pedidos</th>
          <th class="px-4 py-2"></th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">Vendedor</th>
          <th class="border-2 px-4 py-2">Disponibilidad</th>
          <th class="border-2 px-4 py-2">Asignados</th>
          <th class="border-2 px-4 py-2">Espera</th>
          <th class="border-2 px-4 py-2">Ejecución</th>
          <th class="border-2 px-4 py-2">Completados</th>
          <th class="border-2 px-4 py-2">Finalizados</th>
          <th class="border-2 px-4 py-2">Comisiones Totales Bs</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vendedor, k) in arrayVendedoresComputed" :key="k">
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 bg-blue-100'
                : 'border-2 px-4 py-4'
            "
          >
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'Vendedor',
                params: {
                  id: vendedor.vendedor_id,
                  name: vendedor.nombre.replace(/\s/g, '_')
                }
              }"
              >{{ firstLetterUpperCase(vendedor.nombre) }}</router-link
            >
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            <div
              class="text-center text-green-600"
              v-if="vendedor.disponible === '1'"
            >
              Disponible
            </div>
            <div class="text-center text-red-600" v-else>No disponible</div>
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            <span v-if="vendedor.pedidos_RAsignado != '0'">
              {{ vendedor.pedidos_RAsignado }}
            </span>
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            <span v-if="vendedor.pedidos_espera != '0'">
              {{ vendedor.pedidos_espera }}
            </span>
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            <span v-if="vendedor.pedidos_ejecucion != '0'">
              {{ vendedor.pedidos_ejecucion }}
            </span>
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            <span v-if="vendedor.pedidos_completado != '0'">
              {{ vendedor.pedidos_completado }}
            </span>
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            <span v-if="vendedor.pedidos_finalizado != '0'">
              {{ vendedor.pedidos_finalizado }}
            </span>
          </td>
          <td
            :class="
              k % 2 === 0
                ? 'border-2 px-4 py-4 text-center bg-blue-100'
                : 'border-2 px-4 py-4 text-center'
            "
          >
            {{ formato(vendedor.total_comision) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formato, firstLetterUpperCase } from "@/functions.js";

export default {
  props: {
    filtro: String,
    vendedoresTotal: Array
  },
  data() {
    return {
      filtroDisponible: "0"
    };
  },
  methods: {
    formato,
    firstLetterUpperCase
  },
  computed: {
    arrayVendedoresComputed() {
      let arrayVendedores = [];
      let filtroDisponible = "0";

      if (this.filtro === "disponible") {
        filtroDisponible = "1";
      }

      for (let i = 0; i < this.vendedoresTotal.length; i++) {
        if (this.vendedoresTotal[i].disponible === filtroDisponible) {
          arrayVendedores.push(this.vendedoresTotal[i]);
        }
      }
      return arrayVendedores;
    }
  }
};
</script>

<style></style>
