<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between bg-verdedark text-white font-bold w-full fixed md:static"
		>
			<span class="ml-2">Resumen</span>
			<div class="flex items-center">
				<span class="text-white mr-1">Filtar por: </span>
				<select
					id="select"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none"
					v-model="selectFiltro"
				>
					<option value="todos">Todos</option>
					<option value="disponible">Disponible</option>
					<option value="noDisponible">No Disponible</option>
				</select>
			</div>
			<div class="flex items-center justify-center ml-8">
				<router-link to="/AgregarVendedor"
					><button
						@click="$emit('click')"
						class="text-white font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="text-white underline">Agregar vendedor</span>
					</button></router-link
				>
			</div>
		</div>

		<div class="flex items-end justify-end pt-4 mt-20 md:mt-4 mb-4 mr-8">
			<div class="flex items-center justify-center ml-2">
				<span>Total a pagar Bs:</span>
				<div
					v-if="selectFiltro === 'todos'"
					class="ml-2 bg-secund border-secundB border rounded-md w-32 md:w-64 py-1 px-2 text-right"
				>
					{{ formato(totalBs) }}
				</div>
				<div v-else>
					<div
						v-if="selectFiltro === 'disponible'"
						class="ml-2 bg-secund border-secundB border rounded-md w-32 md:w-64 py-1 px-2 text-right"
					>
						{{ formato(totalBsDisponibles) }}
					</div>
					<div
						v-else
						class="ml-2 bg-secund border-secundB border rounded-md w-32 md:w-64 py-1 px-2 text-right"
					>
						{{ formato(totalBsNoDisponibles) }}
					</div>
				</div>
			</div>
		</div>

		<!-- aqui va los componentes con los condicionales -->
		<div v-if="selectFiltro === 'todos'">
			<tabla-vendedores :vendedoresTotal="vendedoresTotal"> </tabla-vendedores>
		</div>
		<div v-if="selectFiltro === 'disponible'">
			<tabla-vendedores-filtro
				:filtro="selectFiltro"
				:vendedoresTotal="vendedoresTotal"
			>
			</tabla-vendedores-filtro>
		</div>
		<div v-if="selectFiltro === 'noDisponible'">
			<tabla-vendedores-filtro
				:filtro="selectFiltro"
				:vendedoresTotal="vendedoresTotal"
			>
			</tabla-vendedores-filtro>
		</div>
	</div>
</template>

<script>
import tablaVendedores from "../components/TablaVendedores.vue";
import tablaVendedoresFiltro from "@/components/TablaVendedoresFiltro.vue";
import { formato, firstLetterUpperCase } from "@/functions.js";

export default {
	components: {
		tablaVendedores,
		tablaVendedoresFiltro
	},
	data() {
		return {
			selectFiltro: "disponible",
			vendedoresTotal: [],
			totalBsNoDisponibles: 0,
			totalBsDisponibles: 0,
			totalBs: 0
		};
	},
	async created() {
		this.$store.state.isLoading = true;
		await this.vendedoresTotalQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		async vendedoresTotalQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/vendedoresTotal.gql"),
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.vendedoresTotal = data.data.vendedoresTotal;
					//Sumatoria de comisiones para vendedores totales, disponibles y no disponible
					for (let i = 0; i < this.vendedoresTotal.length; i++) {
						this.totalBs += parseFloat(this.vendedoresTotal[i].total_comision);

						if (this.vendedoresTotal[i].disponible === "1") {
							this.totalBsDisponibles += parseFloat(
								this.vendedoresTotal[i].total_comision
							);
						} else {
							this.totalBsNoDisponibles += parseFloat(
								this.vendedoresTotal[i].total_comision
							);
						}
					}
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
				});
		}
	}
};
</script>
