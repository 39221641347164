var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center pt-6 mt-20 md:mt-4 pb-10"},[_c('table',{staticClass:"table-auto",attrs:{"id":"Vendedores"}},[_vm._m(0),_vm._m(1),_c('tbody',_vm._l((_vm.arrayVendedoresComputed),function(vendedor,k){return _c('tr',{key:k},[_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 bg-blue-100'
              : 'border-2 px-4 py-4'},[_c('router-link',{staticClass:"hover:text-blue-500 hover:underline",attrs:{"to":{
              name: 'Vendedor',
              params: {
                id: vendedor.vendedor_id,
                name: vendedor.nombre.replace(/\s/g, '_')
              }
            }}},[_vm._v(_vm._s(_vm.firstLetterUpperCase(vendedor.nombre)))])],1),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[(vendedor.disponible === '1')?_c('div',{staticClass:"text-center text-green-600"},[_vm._v(" Disponible ")]):_c('div',{staticClass:"text-center text-red-600"},[_vm._v("No disponible")])]),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[(vendedor.pedidos_RAsignado != '0')?_c('span',[_vm._v(" "+_vm._s(vendedor.pedidos_RAsignado)+" ")]):_vm._e()]),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[(vendedor.pedidos_espera != '0')?_c('span',[_vm._v(" "+_vm._s(vendedor.pedidos_espera)+" ")]):_vm._e()]),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[(vendedor.pedidos_ejecucion != '0')?_c('span',[_vm._v(" "+_vm._s(vendedor.pedidos_ejecucion)+" ")]):_vm._e()]),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[(vendedor.pedidos_completado != '0')?_c('span',[_vm._v(" "+_vm._s(vendedor.pedidos_completado)+" ")]):_vm._e()]),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[(vendedor.pedidos_finalizado != '0')?_c('span',[_vm._v(" "+_vm._s(vendedor.pedidos_finalizado)+" ")]):_vm._e()]),_c('td',{class:k % 2 === 0
              ? 'border-2 px-4 py-4 text-center bg-blue-100'
              : 'border-2 px-4 py-4 text-center'},[_vm._v(" "+_vm._s(_vm.formato(vendedor.total_comision))+" ")])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"px-4 py-2"}),_c('th',{staticClass:"px-4 py-2"}),_c('th',{staticClass:"border-2 px-4 py-2",attrs:{"colspan":"5"}},[_vm._v("Pedidos")]),_c('th',{staticClass:"px-4 py-2"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Vendedor")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Disponibilidad")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Asignados")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Espera")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Ejecución")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Completados")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Finalizados")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Comisiones Totales Bs")])])])}]

export { render, staticRenderFns }